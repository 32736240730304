table {
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #000;
    text-align:left;
    padding: 8px;
    width: 150px;
    vertical-align: top;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }
  